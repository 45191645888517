import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import avatar from '../melogo.jpg';

const GitHubIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12c0 4.41 2.87 8.14 6.84 9.46.5.09.68-.22.68-.48 0-.24-.01-.87-.01-1.71-2.78.61-3.37-1.34-3.37-1.34-.45-1.16-1.1-1.47-1.1-1.47-.9-.61.07-.6.07-.6 1 .07 1.53 1.02 1.53 1.02.89 1.53 2.34 1.09 2.91.83.09-.64.35-1.09.63-1.34-2.22-.25-4.56-1.11-4.56-4.95 0-1.09.39-1.99 1.03-2.69-.1-.25-.45-1.27.1-2.65 0 0 .84-.27 2.75 1.02A9.58 9.58 0 0 1 12 6.8c.85.004 1.71.11 2.5.32 1.9-1.29 2.75-1.02 2.75-1.02.55 1.38.2 2.4.1 2.65.64.7 1.03 1.6 1.03 2.69 0 3.86-2.34 4.7-4.57 4.95.36.31.68.92.68 1.85 0 1.34-.01 2.42-.01 2.75 0 .27.18.58.69.48A10.003 10.003 0 0 0 22 12c0-5.52-4.48-10-10-10z"
      />
    </svg>
  </SvgIcon>
);

const EmailIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 12.713l11.825-8.925c-.15-.041-.303-.072-.46-.072H.635c-.157 0-.309.031-.46.072L12 12.713zm11.951-7.43c.032.073.049.15.049.232v12.961c0 .552-.447 1-1 1H1c-.553 0-1-.448-1-1V5.515c0-.082.017-.159.049-.232L12 14.29l11.951-9.007z"
      />
    </svg>
  </SvgIcon>
);

export default function BioCard() {
  return (
    <div className='flex items-center justify-center outline-color: #fecaca; intro-text mt-10'>
      <Card
        sx={{
          width: 300,
          maxWidth: '100%',
          boxShadow: 'lg',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid black',
        }}
      >
        <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
          <Avatar src={avatar} sx={{ '--Avatar-size': '4rem' }} />
          <Chip
            size="sm"
            variant="soft"
            color="primary"
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            Junior Website Developer
          </Chip>
          <Typography level="title-lg">Jake Mallinson</Typography>
          <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
            I have experience using ReactJS, MySQL, Python, CSS frameworks and JavaScript. Below are some of the sites I’ve made.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 4,
              mt: 2.5,
              '& > a': { borderRadius: '2rem', backgroundColor: 'red', color: 'black' },
            }}
          >
            <a href="https://github.com/Songerwronger" target="_blank" rel="noopener noreferrer">
              <IconButton size="sm" variant="plain" color="black">
                <GitHubIcon />
              </IconButton>
            </a>
            <a href="mailto:jakecod09@gmail.com">
              <IconButton size="sm" variant="plain" color="black">
                <EmailIcon />
              </IconButton>
            </a>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
