import React, { useEffect } from 'react';
import '../App.css'; 

const Background = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollFactor = getScrollFactor();
      const scrollAmount = window.scrollY * scrollFactor;
      document.querySelectorAll('.background-image').forEach((element) => {
        element.style.transform = `translateX(${Math.min(scrollAmount, 200)}%)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollFactors = {
    large: 0.15, // Desktop
    laptop: 0.20, // Ipad
    medium: 0.25, // Laptop
    small: 0.3, // Mobile
  };

  const getScrollFactor = () => {
    if (window.matchMedia("(max-width: 420px)").matches) {
      return scrollFactors.small;
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      return scrollFactors.laptop;
    } else if (window.matchMedia("(max-width: 950px)").matches) {
      return scrollFactors.medium;
    } else {
      return scrollFactors.large;
    }
  }; 

  return (
    <div className="background">
      <div className="background-image image1"></div>
      <div className="background-image image2"></div>
      <div className="background-image image3"></div>
      <div className="background-image image4"></div>
      <div className="background-image image5"></div>
      <div className="background-image image6"></div>
      <div className="background-image image7"></div>
      <div className="background-image image8"></div>
    </div>
  );
};

export default Background;



