import React from 'react';
import Intro from '../comps/intro';

const Home = () => {
  return (
    <div className='text-center mt-10'>
      <Intro />
    </div>
  );
};

export default Home;
