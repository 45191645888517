import React from 'react';
import { NavLink }from 'react-router-dom';
import '../App.css'; 
import '../index.css'; 

const Intro = () => {
  return (
    <div className="intro px-8" style={{zIndex: '-1'}}>
      <h1 className='title-font text-5xl intro-text' style={{ fontWeight: 'bold', marginTop: '10rem',}}>
        <span className="typed-text">Hi, I'm <span className="highlight">Jake</span></span>
      </h1>

      <h2 className='custom-font text-2xl mt-16 intro-text'>
        I’m from <span className='highlight'>Yorkshire, England</span>. I have been creating websites since July 2023 and i specialise in React and CSS Development.
      </h2>
    </div>
  );


}

export default Intro;
