import React from 'react';
import '../index.css';
import malitpicbig from '../malfabitimg.png'; 
import cashitinpic from '../cashitinimg.png';

const ProjectSection = ({ projectTitle, description, techStack, link, image }) => {
  return (
    <div className="project-section flex flex-col lg:flex-row items-center justify-between mb-12">
      <div className="project-image w-full lg:w-1/2" 
           style={{ backgroundImage: `url(${image})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat', 
                    height: '300px', 
                    borderRadius: '12px' }}>
      </div>

      <div className="project-details w-full lg:w-1/2 lg:pl-10 text-white mt-8 lg:mt-0">
        <h2 className="text-4xl font-bold mb-4 title-font">{projectTitle}</h2>
        <p className="text-xl mb-4 custom-font">{description}</p>

        <h3 className="text-2xl font-semibold mb-2">Tech Stack:</h3>
        <ul className="list-disc pl-6 mb-4">
          {techStack.map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}
        </ul>

        <a href={link} target="_blank" rel="noopener noreferrer" className="btn bg-black text-white px-6 py-2 rounded-md">
          Visit Project
        </a>
      </div>
    </div>
  );
};

const Projects = () => {
  return (
    <div className="projects intro-text2" style={{ marginTop: '5rem' }}>
      <div className="project-title text-white title-font text-5xl text-center mb-12">
        <h1 style={{ color: 'black'}}>My Projects</h1>
      </div>

      {/* Project 1 */}
      <ProjectSection
        projectTitle="Malfabit"
        description="This is an IT services site that I made for my own small local business, the site has lead to leads and work for me."
        techStack={['PHP', 'Bootstrap', 'MySQL']}
        link="https://malfabit.com/"
        image={malitpicbig}
      />

      {/* Project 2 */}
      <ProjectSection
        projectTitle="Cash it in"
        description="This is a site for a second hand shop local to me in Goole, they needed a site and asked me to create it for them, they wanted a modern, sleek looking site to let their customers know what services they offer. "
        techStack={['ReactJS', 'Tailwind CSS', 'NodeJS']}
        link="https://www.cash-it-in-goole.com/"
        image={cashitinpic}
      />
    </div>
  );
};

export default Projects;




