import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './comps/navbar';
import Background from './comps/background';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import RoundedSquare from './comps/square';
import Projects from './pages/Projects';
import Bio from './comps/bio'
import './index.css';
import '@fontsource/inter';

const App = () => {
  return (

      

    <Router>
      <div>
        <Background />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
        <div id="about"><Bio /></div>
        <RoundedSquare />
      </div>
    </Router>


  );
};

export default App;



